/* styles.css */
.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Optional: Add a shadow for better separation */
}

.login-nav {
    line-height: 4;
}

.top-header {
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.top-header img {
    max-width: 50px;
    max-height: 50px;
    border-radius: 5px;
}

.nav {
    width: 100%;
}

.nav-item {
    padding: 20px 15px;
    cursor: pointer;
}

.small-nav-container {
    width: 100%
}

.small-nav-item {
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content:center;
}

.body-content {
    padding-top: 100px; /* Adjust based on the height of your header */
}
