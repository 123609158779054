.text {
    line-height: 2;
}
/* sizing */
.contain {
    padding: 0px 100px;
    overflow-x: hidden;
}

.fake {
    margin: 50px;
}

.rotate {
    transform: rotateY(180deg);
}

.rtl {
    direction: rtl;
}

.ltr {
    direction: ltr;
}

.w-100 {
    width: 100%;
}

.w-75 {
    width: 75%;
}

.w-25 {
    width: 25%;
}

/* flex */
.d-flex {
    display: flex;
}

.flex-wrap {
    flex-wrap: wrap;
}

.align-items-center {
    align-items: center;
}

.justify-content-center {
    justify-content: center;
}

.justify-content-start {
    justify-content: right;
}

.justify-content-end {
    justify-content: left;
}


.justify-content-around {
    justify-content: space-around;
}

.flex-column {
    flex-direction: column;
}

.align-items-start {
    align-items: start;
}

a {
    text-decoration: none;
}

/* active style */
.active-color {
    color: #00ac81 !important;
}

.activate-color:hover {
    color: #00ac81;
}

.activate-back:hover {
    background-color: #008c6c;
}


.active-back {
    background-color: #00ac81;
    color: white;
}

.gray-back {
    background-color: gray;
}

.activate-border:focus {
    border-color: #00ac81;
}

.active-border {
    border: 3px #00ac81 solid !important;
}

/* dark and light theme */
.dark {
    background-color: black;
    color: white;
}

.dark-box {
    background-color: #2f2d2d;
    color: white;
}

.light {
    background-color: white;
    color: black;
}

.light-box {
    background-color: #f1f1f1;
    color: black;
}

.white-color {
    color: white;
}

.gray-color {
    color: gray;
}

.black-color {
    color: black;
}

.icon {
    font-size: 140%;
    cursor: pointer;
}

.text-center {
    text-align: center;
}

.justify {
    text-align: justify;
}

.btn {
    padding: 15px;
    border-radius: 10px;
    outline: none;
    border: none;
}

.w-25 {
    width: 25%;
}

.dark-gradient {
    background: linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0));
}

.light-gradient {
    background: linear-gradient(to top, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
}