/* for website */
@font-face {
    font-family: 'vazir';
    src: url('../../fonts/Vazir-Medium.ttf');
}

@font-face {
    font-family: 'vazirBold';
    src: url('../../fonts/Vazirmatn-Black.ttf');
}

/* for tutorials */
@font-face {
    font-family: 'Vazir';
    src: url('../../fonts/Vazir-Medium.ttf');
}

@font-face {
    font-family: 'Vazirmatn Black';
    src: url('../../fonts/Vazirmatn-Black.ttf');
}

.vazir {
    font-family: vazir;
}

.vazir-bold {
    font-family: vazirBold;
}