.h_iframe-aparat_embed_frame {
    position: relative;
}

.h_iframe-aparat_embed_frame,
.ratio {
    display: block;
    width: 100%;
    height: auto;
}

.h_iframe-aparat_embed_frame iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.aparat-span {
    display: block;
    padding-top: 57%;
}