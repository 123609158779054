.symbol img {
    width: 100px;
    height: 120px;
    margin: 15px 0px;
    border-radius: 10px;
}

.site-map {
    width: 100%;
}

.map-ul {
    width: 40%;
    list-style-type: none;
}

.map-ul li a {
    cursor: pointer;
}

.footer-icon {
    margin: 10px;
}