body {
    margin: 0px;
    padding: 0px;
    font-family: vazir;
    overflow-x: hidden;
    line-height:2;
}

body::-webkit-scrollbar {
    display: none;
}

#root {
    width: 100%;
    /*overflow-x: hidden;*/
}

*,
*:focus {
    outline: none
}

.form {
    width: 500px;
    margin: 0;
    font-family: sans-serif;
}

.form-item {
    position: relative;
    margin-bottom: 30px;
}

.form-item input {
    display: block;
    height: 40px;
    border: solid 1px #838383;
    transition: all .3s ease;
    padding: 25px 25px;
    border-radius: 7px;
}

.form-item label {
    position: absolute;
    cursor: text;
    z-index: 2;
    top: 13px;
    right: 10px; /* this part of style make label rtl */
    font-size: 12px;
    font-weight: bold;
    border-radius: 5px;
    padding: 0 10px;
    color: #999;
    transition: all .3s ease;
}

.form-item input:focus + label,
.form-item input:valid + label {
    font-size: 11px;
    top: -5px
}

/* Add animation styles */
.form-item input:focus + label,
.form-item input:valid + label,
.active {
    font-size: 11px;
    top: -5px;
}

.textarea {
    padding: 10px 15px;
    border-radius: 10px;
    max-width: 400px;
    min-width: 300px;
    min-height: 400px;
    max-height: 500px;
}

.form-button {
    width: 150px;
}

.tag {
    padding: 7px 10px;
    margin: 10px 5px;
    border-radius: 6px;
    overflow-x: hidden;
    width: 100px;
    white-space: nowrap;
}

/* table */
.table-container {
  width: 100%;
  overflow-x: auto;
}

table {
    border-collapse: collapse;
    width: 100%;
}

th, td {
    padding: 16px;
    max-width: 100px;
    font-size: 80%;
}

tr:nth-child(even) {
    background-color: gray;
}

h1 {
    font-size: 150%;
}