/*.first-section {*/
/*    background-image: url('http://127.0.0.1:8000/public/media/assets/base.png');*/
/*    background-image: url('https://picodevgit.com/public/media/assets/base.png');*/
/*    background-size: cover;*/
/*    background-repeat:repeat;*/
/*    background-position: center;*/
/*}*/

.first-photo {
    width: 550px;
    height: 500px;
}

.rotate180 {
    transform: rotate(180deg);
}

.category-container {
    padding: 20px 7px;
    border-radius: 10px;
    margin: 30px 0px;
    word-break: break-all;
    height: 170px;
    overflow-x: hidden;
    overflow-y: hidden;
}

.category-block {
    padding: 0px 50px;
}

.category-img {
    width: 80px;
    height: 80px;
}
/* parallax style */
/* TODO: parallax container and gradient should have equal height */
.parallax-container {
    position: relative;
    width: 100%;
    height: 400px; /* equal to parallax gradient height */
    overflow-x: hidden;
    overflow-y: hidden;
    scrollbar-width: none; /* Firefox */
}

.parallax-container::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera */
}

.parallax-gradient {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 400px; /* equal to parallax container height */
    padding: 20px 0;
    z-index: 2;
}