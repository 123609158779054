.article-cover {
    width: 100%;
    max-height: 60vh;
}

.tutorial-list-container {
    position: fixed;
    z-index: 10;
    overflow-y: scroll;
    overflow-x: hidden;
    top: 0; /* Ensure it starts from the top */
    bottom: 0; /* Ensure it spans to the bottom */
    /*width: 100%; !* Adjust as needed *!*/
    scrollbar-width: none; /* Firefox */
}

.tutorial-list-container::-webkit-scrollbar {
    display: none;
}

.list-shower {
    position: fixed;
    bottom: 50px;
    left: 50px;
    padding: 13px 25px;
    border-radius: 50%;
    display: none;
}

.list-hider {
    position: absolute;
    right: 0;
    bottom: 200px;
    padding: 15px;
    border-radius: 30%;
    display: none;
}

.w-15-tutorial {
    width: 15%;
}

.tutorial-content {
    width: 75%;
}

.tutorial-list {
    text-decoration: none;
    list-style-type: none;
    padding: 0;
    margin: 0;
    overflow-x: hidden;
    overflow-y: scroll;
    max-height: 100%;
    scrollbar-width: none; /* Firefox */
}

.tutorial-list::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
}


.tutorial-list-title {
    padding: 7px 12px 7px 12px;
    word-wrap: break-word;
    border-bottom: 1px solid gray;
}

.tutorial-list-item {
    padding: 7px 12px 7px 12px;
    width: 100%;
    word-wrap: break-word;
    border-bottom: 1px solid gray;
}

.tutorial-list-item:hover {
    background-color: gray;
    cursor: pointer;
}

.tutorial-list-link {
    width: 100%;
}

.tutorial-list-h2 {
    padding: 10px 0 0 0;
    font-size: 120%;
}

.list-times-icon {
    display: none;
}