/* xxl */
@media screen and (min-width: 1200px) {
    .contain {
        padding: 0px 90px;
    }

    .text {
        font-size: 100%
    }

    #small-nav {
        display: none;
    }
}

/* lg */
@media screen and (max-width: 1200px) {
    .text {
        font-size: 100%
    }

    #small-nav {
        display: none;
    }
}

/* md */
@media screen and (max-width: 992px) {
    .contain {
        padding: 0px 70px;
    }

    .text {
        font-size: 95%
    }

    #small-nav {
        display: none;
    }

    .w-15-tutorial {
        display: none;
    }

    .tutorial-content {
        width: 100%;
    }

    .tutorial-list-container {
        display: none;
        width: 250px;
    }

    .list-shower {
        display: block;
    }

    .list-hider {
        display: block;
    }

    .list-times-icon {
        display: block;
    }
}

/* sm */
@media screen and (max-width: 768px) {
    .service-card {
        padding: 30px;
    }

    .contain {
        padding: 0px 40px;
    }

    h1 {
        font-size: 160%;
    }

    .text {
        font-size: 90%
    }

    #big-nav {
        display: none;
    }

    #small-nav {
        display: flex;
    }

    .w-15-tutorial {
        display: none;
    }

    .tutorial-content {
        width: 100%;
    }

    .tutorial-list-container {
        display: none;
        width: 250px;
    }

    .list-shower {
        display: block;
    }

    .list-hider {
        display: block;
    }

    .list-times-icon {
        display: block;
    }
}

/* xxs */
@media screen and (max-width: 576px) {
    .service-card {
        padding: 15px;
    }

    .contain {
        padding: 0px 20px;
    }

    h1 {
        font-size: 160%;
    }

    .text {
        font-size: 80%
    }

    #small-nav {
        display: flex;
    }

    .first-photo {
        width: 290px;
        height: 290px;
    }

    .profile-photo {
        width: 150px !important;
        height: 150px !important;
        background: red;
    }

    #root {
        overflow-x: hidden;
    }

    .w-15-tutorial {
        display: none;
    }

    .tutorial-content {
        width: 100%;
    }

    .tutorial-list-container {
        display: none;
        width: 250px;
    }

    .list-shower {
        display: block;
    }

    .list-hider {
        display: block;
    }

    .list-times-icon {
        display: block;
    }
}

