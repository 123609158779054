.profile-photo {
    border-radius: 50%;
    width: 200px;
    height: 200px;
}

.profile-info {
    margin: 10px 0;
}

.skill-box {
    display: flex;
    justify-content: right;
    flex-direction: column;
    align-items: start
}